<template>
  <div class="editProfile container">
    <div class="row col-gap-60">
      <div class="col-md-6">
        <div class="topHead">
          <h1>{{$t('global.edit_profile')}} ({{ `${profile.followers == 0 ? 0 : profile.followers}` }} {{$t('global.followers')}})</h1>
          <router-link :to="$i18nRoute({name: 'userEarning'})" class="btn btn-primary">
            {{ profile.currency }} {{ profile.balance }}
            <svg-icon icon-id="amount" icon-viewbox="0 0 21.302 19.05">
            </svg-icon>
          </router-link>
        </div>
        <base-alerts />
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
          <b-form @submit.stop.prevent="handleSubmit(onSubmit)">
            <div class="row">
              <div class="col-md-6">
                <base-input
                  v-bind:placeholder="$t('First Name')"
                  type="text"
                  rules="required"
                  :value="profile.first_name"
                  v-model="profile.first_name"
                  name="First Name"
                />
              </div>
              <div class="col-md-6">
                <base-input
                  v-bind:placeholder="$t('Last Name')"
                  type="text"
                  rules="required"
                  :value="profile.last_name"
                  v-model="profile.last_name"
                  name="Last Name"
                />
              </div>
            </div>
            <div class="row">
              <base-date-picker
                :value="profile.birthdate"
                v-model="profile.birthdate"
                name="DOB"
                rules="required"
              />
              <base-input
                v-bind:placeholder="$t('Change Password')"
                type="password"
                rules="min:8"
                :value="password"
                v-model="password"
                name="Password"
                v-if="showPassword"
              />
              <base-input
                v-bind:placeholder="$t('Confirm Change Password')"
                type="password"
                rules="confirmed:Password|min:8"
                :value="retype"
                v-model="retype"
                name="Confirm Password"
                v-if="showPassword"
              />
              <!--<base-input
                placeholder="Address"
                type="text"
                :value="profile.address"
                v-model="profile.address"
                rules="required"
                name="Address"
              />-->
              
              <!-- Location Changes Start --> <!-- Location Changes Start -->
              <base-locations-country
                rules="required"
                v-model="selectedCountry"
                name="Country"
                :locations="locations"
                @input="getStates"
                id="id"
              ></base-locations-country>
              
              <base-locations-state
                rules="required"
                v-model="selectedState"
                name="State"
                :states="states"
                @input="getCities"
                id="id"
              ></base-locations-state>
              
              <base-locations-city
                rules="required"
                v-model="selectedCity"
                name="City"
                :cities="cities"
                id="id"
              ></base-locations-city>
              <!-- Location Changes End   --> <!-- Location Changes End   -->
              
              <base-file-upload
                v-model="image"
                v-bind:label="$t('Add Profile Image')"
              ></base-file-upload>
              <!--Tags section-->
              <div v-if="userTags">
                <hr />
                <h2>{{$t('editProfile.tags')}}</h2>
                <!-- Dropdown -->
                <base-multi-select
                  :list="userTags"
                  :multiple="true"
                  label="tags"
                  id="id"
                  v-model="selectedTag"
                  @input='getSelectedTag'
                  class='filter-tags'
                  v-bind:placeHolder="$t('Select Tags')"
                >
                </base-multi-select>
              </div>
              <!--Tags section-->
              <div v-if="influencerAccounts">
                <hr />
                <h2>{{$t('editProfile.price_per_story')}}</h2>
                <!-- Dropdown -->
                <base-multi-select
                  :list="influencerAccounts"
                  label="name"
                  track-by="id"
                  v-model="socialAccount"
                  value="1"
                ></base-multi-select>
                <base-input
                  placeholder="Story Price"
                  type="text"
                  v-model="storyPrice"
                  rules="required"
                  name="Story Price"
                />
                <hr />
              </div>

              <connect-social-account :callback-url="`/${currentLang}/user/edit-profile`" />

              <div class="button-row">
                <button
                  class="btn btn-primary"
                  :class="processing ? 'processing' : ''"
                >
                  {{ buttonText }}
                </button>
              </div>
            </div>
          </b-form>
        </validation-observer>
      </div>
      <div class="col-md-6 text-right">
        <div class="editProfile__image">
          <img
            src="@/assets/images/edit-profile.png"
            alt=""
            class="img-fluid"
          />
        </div>
      </div>
    </div>
  <div ref="scrollToConnectMedia"></div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import {
  UPDATE_USER,
  UPDATE_PASSWORD,
  UPLOAD_IMAGE,
} from "@/graphql/common/mutations";
import { UPDATE_STORY_PRICE, UPDATE_USER_TAGS } from "@/graphql/user/mutations";
import { USERS_TAGS } from "@/graphql/user/query";
import { LOCATIONS } from "@/graphql/common/query";
import { MESSAGES } from "@/_helpers/notifications";
import cloneDeep from "lodash/cloneDeep";
import { Trans } from "@/plugins/Translation";

export default {
  data() {
    return {
      processing: false,
      profile: {},
      password: null,
      retype: null,
      image: null,
      buttonText: this.$i18n.t("Save"),
      data: {
        firstName: "",
        lastName: "",
        birthdate: "",
        address: "",
      },
      socialAccount: {
        id: 0,
        name: "",
      },
      storyPrice: null,
      userTags: [],
      selectedTag: [],
      locations: [],
      countries: [],
      selectedCountry: '',
      states: [],
      selectedState: '',
      cities: [],
      selectedCity: '',
      showPassword: false,
      currentLang: Trans.currentLanguage,
    };
  },
  components: {
    connectSocialAccount: () =>
      import(
        /* webpackChunkName: "connectSocialAccount" */ "@/components/user/register/ConnectSocialAccount.vue"
      ),
  },
  computed: {
    ...mapGetters({
      user: "user",
      influencerAccounts: "mediaAccounts/influencerAccounts",
    }),
  },
  created() {
    this.setUser();
    //this.getSelectedTag();//for testing purpose
  },
  mounted() {
    if (this.influencerAccounts.length !== 0) {
      this.socialAccount.id = this.influencerAccounts[0].id;
      this.socialAccount.name = this.influencerAccounts[0].name;
      this.storyPrice = this.influencerAccounts[0].price;
    }
    if (this.$route.hash === "#connect-media-account") {
      this.$refs.scrollToConnectMedia.scrollIntoView({ behavior: "smooth" });
    }
    if (this.user.location !== null && this.user.location.id !== 0) {
      this.selectedCountry = this.user.location; 
      this.selectedState = this.user.location;
      this.selectedCity = this.user.location;
    }
    this.showPassword = this.user.login_type === "facebook" ? false : true;
  },
  watch: {
    user() {
      this.setUser();
    },
    socialAccount() {
      let account = {};
      account = this.influencerAccounts.find(
        (account) => account.id === this.socialAccount.id
      );
      this.storyPrice = account.price;
    },
  },
  apollo: {
    userTags: {
      query: USERS_TAGS,
    },
    locations: {
      query: LOCATIONS,
      variables() {
        return {
          name: 'all',
        };
      },
    },
  },
  methods: {
    ...mapActions(["setUser"]),
    ...mapActions(["updateUser"]),
    ...mapActions("mediaAccounts", ["setMediaAccount"]),
    setUser() {
      this.profile = cloneDeep(this.user);
      this.selectedTag = this.user.uTags;
    },
    onSubmit() {
      const id_location = this.selectedCity === null ? '0' : parseInt(this.selectedCity.id,10);
      this.processing = true;
      (this.data.firstName = this.profile.first_name),
        (this.data.lastName = this.profile.last_name),
        (this.data.birthdate = this.profile.birthdate),
        (this.data.address = this.profile.address),
        (this.data.idLocation = id_location);
      this.updateUserTag();//Himanshu
      this.updateProfile();
    },
    async updateProfile() {
      if (this.socialAccount.id != 0) {
        this.updateMediaAccount();
      }
      if (this.password != null) {
        this.buttonText = this.$i18n.t("Updating Password")+"...";
        await this.$apollo
          .mutate({
            mutation: UPDATE_PASSWORD,
            variables: {
              password: this.password,
              retype: this.retype,
            },
          })
          .then((data) => {
            if (data) {
              if (data.data.updatePassword.state == "success") {
                this.updateUserInfo();
              }
            }
          })
          .catch((e) => {
            this.handleError(e);
            this.processing = false;
          });
      } else {
        this.updateUserInfo();
      }
    },
    async updateProfileImage() {
      if (this.image != null) {
        this.buttonText = this.$i18n.t("Uploading Image")+"...";
        await this.$apollo
          .mutate({
            mutation: UPLOAD_IMAGE,
            variables: {
              image: this.image,
            },
          })
          .then((data) => {
            if (data.data.uploadProfile.value != null) {
              this.$store.state.auth.user.avatar =
                data.data.uploadProfile.value;
            }
          })
          .catch((e) => {
            this.handleError(e);
          });
      }
    },
    async updateUserInfo() {
      await this.updateProfileImage();
      this.buttonText = this.$i18n.t("Updating Info")+"...";
      await this.$apollo
        .mutate({
          mutation: UPDATE_USER,
          variables: this.data,
        })
        .then((data) => {
          if (data) {
            if (data.data.updateUser.state == "success") {
              this.notify("success", MESSAGES.SUCCESS);
              this.updateUser();
            } else {
              this.notify("error", data.data.updateUser.state);
            }
          }
          this.buttonText = this.$i18n.t("Save");
          this.processing = false;
        })
        .catch((e) => {
          this.handleError(e);
          this.processing = false;
        });
    },
    updateMediaAccount() {
      this.$apollo
        .mutate({
          mutation: UPDATE_STORY_PRICE,
          variables: {
            account_id: this.socialAccount.id,
            price: this.storyPrice,
          },
        })
        .then((data) => {
          if (data) {
            if (
              data.data.updateMediaAccount.state == "added" ||
              data.data.updateMediaAccount.state == "updated"
            ) {
              const iA = this.influencerAccounts;
              const index = iA.findIndex(
                (element) => element.id === this.socialAccount.id
              );
              const elem = iA.find(
                (element) => element.id === this.socialAccount.id
              );
              elem.price = this.storyPrice;
              iA[index] = elem;
              this.setMediaAccount(iA);
            }
          }
        })
        .catch((e) => {
          this.handleError(e);
          this.processing = false;
        });
    },
    getSelectedTag() {
      this.selectedTag.map((items) => items.tags).toString();
    },
    async updateUserTag() {
      let newTags = this.selectedTag.map((items) => (items.id));
      await this.$apollo
      .mutate({
        mutation: UPDATE_USER_TAGS,
        variables: {
              id: newTags
            },
      })
      .then(async (data) => {
        if (data) {
          if (
            data.data.updateUsersTags.state == "added" ||
            data.data.updateUsersTags.state == "updated"
          ) {
            await this.updateUser();
          }
        }
      })
      .catch((e) => {
        this.handleError(e);
        this.processing = false;
      });
    },
    getStates() {
      this.states = [];
      this.selectedState = '';
      this.selectedCity = '';
      if (this.selectedCountry !== null) { 
        this.$apollo
        .query({
          query: LOCATIONS,
          variables: {
            search: this.selectedCountry.country,
            name: 'country',
          }
        })
        .then((data) => {
          if (data.data.locations.length > 0) {
            for (var i = 0; i < data.data.locations.length; i++) {
              this.states.push(data.data.locations[i]);
            }
          }
        })
        .catch((e) => {
          this.handleError(e);
        });
      }
    },
    getCities() {
      this.cities = [];
      this.selectedCity = '';
      if (this.selectedState !== null) {
        this.$apollo
        .query({
          query: LOCATIONS,
          variables: {
            search: this.selectedState.state,
            name: 'state',
          }
        })
        .then((data) => {
          if (data.data.locations.length > 0) {
            for (var i = 0; i < data.data.locations.length; i++) {
              this.cities.push(data.data.locations[i]);
            }
          }
        })
        .catch((e) => {
          this.handleError(e);
        });  
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.invalid-feedback {
  position: static !important;
}
.editProfile {
  .topHead {
    margin-bottom: 12px;
    align-items: center;
    h1 {
      margin: 0;
    }
    a {
      width: auto;
      font-size: rem(14px);
      height: auto;
      font-weight: 900;
      border-radius: 50px;
      min-height: auto;
      @include flex(center, center);
      flex-wrap: nowrap;
      padding: rem(10px) rem(54px);
      &:hover {
        &::before {
          border-radius: 50px;
        }
      }
      svg {
        width: 20px;
        margin-left: 13px;
      }
    }
  }
  .UploadImage {
    overflow: hidden;
    text-align: center;
    @include flex(center, center);
    ::v-deepimg {
      width: 150px;
      height: 150px;
      object-fit: cover;
      border-radius: 100%;
      margin: 10px;
      @media screen and (max-width: 767px) {
        width: 150px;
        height: 150px;
      }
    }
  }
  h1,
  h2 {
    font-size: rem(20px);
    margin-bottom: rem(16px);
    font-weight: 600;
  }
  form {
    max-width: 100%;
  }
  .form-control {
    padding: rem(14px) rem(10px);
    border: 1px solid #caced5;
    border-radius: 4px;
    font-size: rem(16px);
    font-weight: 400;
    color: var(--textPrimary);
    &::placeholder {
      opacity: 0.8;
    }
    &:focus {
      border: 1px solid var(--primary) !important;
    }
  }
  .socialLinks {
    list-style: none;
    @include flex(center, space-between);
    padding: 0 10px;
    li {
      flex: 0 0 48%;
      max-width: 48%;
      margin-bottom: 12px;
      img {
        margin-right: 6px;
      }
      a {
        font-size: 14px;
        font-weight: 600;
        color: var(--textPrimary);
        border: 1px solid #caced5;
        border-radius: 50px;
        @include flex(center, center);
        padding: rem(18px) rem(10px);
        text-decoration: none;
        min-width: 213px;
        min-height: 65px;
        background-color: #fff;
        img {
          width: 21px;
          height: 21px;
        }
        @media screen and (min-width: 1025px) {
          &:hover {
            border-color: var(--primary);
          }
        }
      }
    }
    @media screen and (max-width: 991px) {
      li {
        a {
          min-width: auto;
        }
      }
    }
    @media screen and (max-width: 575px) {
      li {
        flex: 0 0 100%;
        max-width: 100%;
        a {
          min-height: 55px;
        }
      }
    }
  }
  .button-row {
    margin-top: 0;
    padding: 0 10px;
    .btn {
      width: 100%;
    }
  }
  .form-group {
    &.upload {
      background: #e8e8f0;
      border-radius: 8px;
      height: 52px;
      position: relative;
      margin: 0;
      width: 96%;
      margin: auto;
      margin-bottom: 11px;
      transition: 0.4s ease all;
      span {
        position: absolute;
        font-size: rem(20px);
        font-weight: 600;
        color: var(--textPrimary);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        text-align: center;
        @media screen and (max-width: 767px) {
          font-size: rem(18px);
        }
      }
      input {
        width: 100%;
        height: 100%;
        opacity: 0;
        z-index: 2;
      }
      @media screen and (min-width: 1025px) {
        &:hover {
          background: var(--primary);
          span {
            color: #fff;
          }
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    > .row {
      flex-direction: column-reverse;
    }
    &__image {
      max-width: 300px;
      margin: auto auto rem(30px) auto;
    }
  }
}
::v-deep {
  .b-form-btn-label-control {
    border-radius: 4px;
    border: 1px solid #ced4da !important;
  }
  .b-form-datepicker.b-form-btn-label-control.form-control > .form-control {
    border-radius: 8px;
    color: var(--textPrimary) !important;
    font-size: rem(16px) !important;
    font-weight: 400 !important;
    /*padding-left: rem(24px);*/
  }
  .b-form-datepicker .form-control.text-muted {
    opacity: 0.8;
  }
  .filter-tags {
    .multiselect{
      &__content {
          max-height: 250px;
          overflow: auto;
      }
    }
  }
}
</style>
